<template>
  <div class="container">
      <Title msg="Perfil"/>
      <ProfileIndex />
  </div>
</template>
<script>
import ProfileIndex from '@/components/student/profile/index.vue'
import Title from '@/views/student/constants/title.vue'

export default {
  name: 'dataStudent',
  components: {
    ProfileIndex,
    Title
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scope>

</style>
