<template>
  <div>
    <div class="row justify-content-center text-center backgroundForm">
      <div class="col-8 col-md-6 col-sm-6 my-4">
        <div class="imgav" v-if="minAvatar">
          <div class="cAvatar">
            <img :src="minAvatar" class="cImage" />
          </div>
        </div>
        <div class="imgav" v-else>
          <div class="cAvatar">
            <img :src="appDomainImg + 'profile/15.' + student.user.avatar" class="cImage" />
          </div>
        </div>
        <p>{{ this.successMessage }}</p>
        <h3>{{ student.user.name }}</h3>
      </div>
    </div>

    <div class="row justify-content-center text-center backgroundForm">
      <div class="col-8 col-md-6 col-sm-6 my-4">
        <h5 class="my-4">Cambiar bloque</h5>
        <form action="" method="POST">
          <div class="form-group">
            <select
              name="block-id"
              class="custom-select"
              v-model="blockId"
              @change="updateBlock"
            >
              <option v-for="block in blocks" v-bind:value="block.id" :key="block.id">
                {{ block.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </div>

    <div class="row justify-content-center backgroundForm">
      <form class="form-inline">
        <div class="form-group row align-items-center" hidden>
          <div class="alert alert-info" role="alert">
            Pronto recibirás un correo electrónico para cambiar tu contraseña.
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col col-form-label col-form-label-md">Cambiar contraseña</label>
          <div class="col-">
            <button type="submit" class="btn btn-light btn-sm" @click="sendEmailForgote">
              Enviar email
            </button>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "profileIndex2",
  components: {},
  data() {
    return {
      minAvatar: "",
      avatar: "",
      blockId: "1",
      //blocks: [],
      successMessage: "",
    };
  },
  async created() {
    await this.$store.dispatch("setBlocks");
    await this.$store.dispatch("setBlock");
    await this.$store.dispatch("setStudent");
    //this.getBlocks();
  },
  mounted() {
    this.blockId = this.block.block_id;
  },
  methods: {
    getBlocks() {
      const url = this.appDomainApi1 + "profile/getblocks";
      axios
        .get(url)
        .then((res) => {
          this.blocks = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async updateBlock(e) {
      e.preventDefault();
      const url = this.appDomainApi1 + "profile/userBlockUp";
      const params = { block_id: this.blockId };
      await axios
        .post(url, params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
      const currentStorage = window.localStorage.getItem("block");
      if (currentStorage) {
        await window.localStorage.removeItem("block");
      }
      await this.$store.dispatch("setBlock");
    },
    sendEmailForgote(e) {
      e.preventDefault();
      const url = this.appDomainApi1 + "email/forgot-password";
      const params = { email: this.student.email };
      axios
        .post(url, params)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState([
      "blocks",
      "student", 
      "block"
      ]),
  },
};
</script>

<style scoped>
.backgroundForm {
  background: white;
  border-radius: 10px;
  margin-top: 15px;
}
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}

.cAvatar {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}

.cImage {
  position: absolute;
  width: 150px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
